<template>
  <div class="d-flex align-center justify-space-between mb-2">
    <!-- ЛЕВАЯ СТОРОНА -->
    <div />

    <!-- ПРАВАЯ СТОРОНА -->
    <div class="d-flex align-center">
      <!-- ДОБАВИТЬ БИЛД -->
      <v-tooltip
        v-if="profile.manageBrowserBuilds"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            color="primary"
            icon
            class="mr-2"
            v-on="on"
            @click="addBuild"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Добавить билд</span>
      </v-tooltip>

      <!-- ОБНОВИТЬ СПИСОК -->
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            class="ml-2 mr-4"
            color="primary"
            icon
            :disabled="loading.mainTable"
            v-on="on"
            @click="$store.dispatch('browserBuilds/loadCurrentPage')"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>

      <!-- ПОИСК -->
      <!-- <v-text-field
        :value="topbarSearch.searchInput"
        label="Поиск"
        dense
        solo
        single-line
        hide-details
        clearable
        clear-icon="mdi-close"
        :spellcheck="false"
        @input="search"
      >
        <template #prepend-inner>
          <v-icon size="18">
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'BrowserBuildsTopbar',

  computed: {
    ...mapGetters({
      loading: 'browserBuilds/loading',
      profile: 'main/profile',
    }),
    ...mapFields('browserBuilds', ['topbarSearch']),
  },

  methods: {
    async addBuild() {
      await this.$store.dispatch('browserBuilds/setBuildForMainDialog');
      this.$store.dispatch('browserBuilds/openDialog', 'mainDialog');
    },

    search(name) {
      this.topbarSearch.searchInput = name;
      setTimeout(async () => {
        if (name === this.topbarSearch.searchInput) {
          this.$store.dispatch('browserBuilds/loadBrowserBuilds', { query: this.topbarSearch.searchInput });
        }
      }, 500);
    },
  },
};
</script>
