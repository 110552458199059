<template>
  <v-card>
    <!-- ТАБЛИЦА -->
    <v-data-table
      :items="browserBuilds.all"
      item-key="id"
      :headers="cols"
      fixed-header
      :height="innerHeight - 16 - 46 - 59"
      :items-per-page="footerOptions.itemsPerPage"
      hide-default-footer
      :loading="loading.mainTable"
    >
      <!-- ССЫЛКИ -->
      <template #[`item.links`]="{item}">
        <a
          v-if="item.pipelineId"
          target="_blank"
          :href="`https://gitlab.dolphin-software.online/infra/anty-browser-builds/-/pipelines/${item.pipelineId}`"
        >#{{ item.pipelineId }}</a>

        <div v-if="item.status === 'success'">
          <a
            :href="`https://storage.yandexcloud.net/anty-browser/${item.id}/mac-arm.zip`"
            target="_blank"
            class="mr-1"
          >mac-arm</a>
          <a
            :href="`https://storage.yandexcloud.net/anty-browser/${item.id}/mac-intel.zip`"
            target="_blank"
            class="mx-1"
          >mac-intel</a>
          <a
            :href="`https://storage.yandexcloud.net/anty-browser/${item.id}/windows.zip`"
            target="_blank"
            class="mx-1"
          >win</a>
          <a
            :href="`https://storage.yandexcloud.net/anty-browser/${item.id}/linux.zip`"
            target="_blank"
            class="mx-1"
          >linux</a>
        </div>
      </template>
      <!-- ИМЯ -->
      <template #[`item.status`]="{item}">
        <v-chip
          v-if="item.status === 'failed' || item.status === 'canceled'"
          label
          small
          outlined
          color="red"
        >
          {{ item.status.toUpperCase() }}
        </v-chip>

        <v-chip
          v-else-if="item.status === 'new' || item.status === 'pending' || item.status === 'waiting' || item.status === 'running'"
          label
          small
          outlined
          color="warning"
        >
          {{ item.status.toUpperCase() }}
        </v-chip>

        <v-chip
          v-else-if="item.status === 'success'"
          label
          small
          outlined
          color="success"
        >
          {{ item.status.toUpperCase() }}
        </v-chip>
      </template>

      <!-- ФУТЕР -->
      <template #footer>
        <v-data-footer
          :options="footerOptions"
          :pagination="footerPagination"
          :items-per-page-options="[10, 30, 50]"
          @update:options="updateFooterOptions"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'BrowserBuildsMainTable',

  computed: {
    ...mapGetters({
      loading: 'browserBuilds/loading',
      innerHeight: 'main/innerHeight',
      pagination: 'browserBuilds/pagination',
    }),
    ...mapFields('browserBuilds', ['browserBuilds']),

    cols() {
      const cols = [];

      cols.push({ text: 'Версия', value: 'id', width: 100 });
      cols.push({ text: 'Автор', value: 'email', width: 200 });
      cols.push({ text: 'Комментарий', value: 'comment' });
      cols.push({ text: 'Ссылки', value: 'links', width: 250 });
      cols.push({ text: 'Статус', value: 'status', width: 100 });

      return cols;
    },

    footerOptions() {
      const options = {};

      options.page = this.pagination.currentPage;
      options.itemsPerPage = this.pagination.perPage;

      return options;
    },

    footerPagination() {
      const pagination = {};

      pagination.page = this.pagination.currentPage;
      pagination.itemsPerPage = this.pagination.perPage;
      pagination.pageStart = this.pagination.from - 1;
      pagination.pageStop = this.pagination.to;
      pagination.pageCount = this.pagination.lastPage;
      pagination.itemsLength = this.pagination.total;

      return pagination;
    },
  },

  methods: {
    updateFooterOptions(options) {
      this.$store.dispatch('browserBuilds/loadBrowserBuilds', { page: options.page, itemsPerPage: options.itemsPerPage });
    },
  },
};
</script>
