<template>
  <div>
    <main-dialog v-if="dialogs.mainDialog" />
    <topbar />
    <main-table />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MainTable from '@/components/browserBuilds/BrowserBuildsMainTable.vue';
import Topbar from '@/components/browserBuilds/BrowserBuildsTopbar.vue';
import MainDialog from '@/components/browserBuilds/BrowserBuildsMainDialog.vue';

export default {
  name: 'BrowserBuilds',

  components: {
    MainTable,
    Topbar,
    MainDialog,
  },

  computed: {
    ...mapGetters({
      dialogs: 'browserBuilds/dialogs',
    }),
  },

  created() {
    this.$store.dispatch('browserBuilds/loadBrowserBuilds');
  },

  beforeDestroy() {
    this.$store.dispatch('browserBuilds/clearSelected');
  },
};
</script>
