<template>
  <v-dialog
    :value="dialogs.mainDialog"
    width="400"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Добавить билд
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <!-- LINUX -->
        <!-- <v-row>
          <v-col
            :cols="6"
            class="pb-0 mb-0"
          >
            <v-text-field
              v-model="links.linux"
              label="Linux"
            />
          </v-col>
          <v-col
            :cols="6"
            class="pb-0 mb-0"
          >
            <v-text-field
              v-model="md5.linux"
              label="md5"
            />
          </v-col>
          <v-progress-linear
            v-if="progress.linux > 0 && progress.linux < 100"
            v-model="progress.linux"
            value="progress.linux"
            buffer-value="0"
            stream
          />
        </v-row> -->

        <!-- WINDOWS -->
        <!-- <v-row class="pt-6">
          <v-col
            :cols="6"
            class="pb-0 mb-0"
          >
            <v-text-field
              v-model="links.windows"
              label="Windows"
            />
          </v-col>
          <v-col
            :cols="6"
            class="pb-0 mb-0"
          >
            <v-text-field
              v-model="md5.windows"
              label="md5"
            />
          </v-col>
          <v-progress-linear
            v-if="progress.windows > 0 && progress.windows < 100"
            v-model="progress.windows"
            value="progress.windows"
            buffer-value="0"
            stream
          />
        </v-row> -->

        <!-- MACOS INTEL -->
        <!-- <v-row class="pt-6">
          <v-col
            :cols="6"
            class="pb-0 mb-0"
          >
            <v-text-field
              v-model="links.macIntel"
              label="macOS (Intel)"
            />
          </v-col>
          <v-col :cols="6">
            <v-text-field
              v-model="md5.macIntel"
              label="md5"
            />
          </v-col>
          <v-progress-linear
            v-if="progress.macIntel > 0 && progress.macIntel < 100"
            v-model="progress.macIntel"
            value="progress.macIntel"
            buffer-value="0"
            stream
          />
        </v-row> -->

        <!-- MACOS ARM -->
        <!-- <v-row class="pt-6">
          <v-col
            :cols="6"
            class="pb-0 mb-0"
          >
            <v-text-field
              v-model="links.macArm"
              label="macOS (ARM)"
            />
          </v-col>
          <v-col
            :cols="6"
            class="pb-0 mb-0"
          >
            <v-text-field
              v-model="md5.macArm"
              label="md5"
            />
          </v-col>
          <v-progress-linear
            v-if="progress.macArm > 0 && progress.macArm < 100"
            v-model="progress.macArm"
            value="progress.macArm"
            buffer-value="0"
            stream
          />
        </v-row> -->

        <v-row>
          <v-col>
            <v-textarea
              v-model="comment"
              rows="5"
              outlined
              label="Комментарий"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="primary"
          :disabled="!comment || comment.length < 5"
          @click="sendBuild"
        >
          Добавить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'BrowserBuildsMainDialog',

  data() {
    return {
      comment: null,
      macIntel: '',
      macArm: '',
      linux: '',
      windows: '',
      progress: {
        linux: 0,
        windows: 0,
        macArm: 0,
        macIntel: 0,
      },
      links: {
        linux: '',
        windows: '',
        macArm: '',
        macIntel: '',
      },
      md5: {
        linux: '',
        windows: '',
        macArm: '',
        macIntel: '',
      },
    };
  },

  computed: {
    ...mapGetters({ dialogs: 'browserBuilds/dialogs' }),
    ...mapFields('browserBuilds', ['forMainDialog']),
  },

  beforeDestroy() {
    this.$store.dispatch('browserBuilds/clearForDialog');
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('browserBuilds/closeDialog', 'mainDialog');
    },
    async handleFileSelect(file, platform) {
      if (file.type !== 'application/zip') {
        this.$store.dispatch('main/alert', {
          color: 'error',
          message: 'Загружайте архив .zip',
        });
      } else if (!this.md5[platform] || this.md5[platform].length !== 32) {
        this.$store.dispatch('main/alert', {
          color: 'error',
          message: 'Нет md5 хеша, не могу загрузить',
        });
      } else {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('platform', platform);
        formData.append('md5', this.md5[platform]);
        this.api.post('/admin/browser_builds/build',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: function (progressEvent) {
              const progress = (progressEvent.loaded / progressEvent.total) * 100;
              this.progress[platform] = parseInt(Math.round(progress), 10);
            }.bind(this),
          }).then((a) => {
          console.log(a);
          if (a.status === 200 && a.data && a.data.file) {
            this.links[platform] = a.data.file;
          } else if (a.status === 422 && a.data && a.data.error === 'Invalid md5') {
            this.$store.dispatch('main/alert', {
              color: 'error',
              message: 'Неверный md5',
            });
          }
        })
          .catch(() => {
            console.log('failure');
          });
      }
    },
    async sendBuild() {
      try {
        this.loading = true;
        const payload = {
          macIntel: this.links.macIntel,
          macArm: this.links.macArm,
          linux: this.links.linux,
          windows: this.links.windows,
          md5: this.md5,
          comment: this.comment,
        };
        console.log(payload);
        const response = await this.api.post('/admin/browser_builds/', payload);

        if (response.status === 200) {
          this.closeDialog();
          this.$store.dispatch('browserBuilds/loadCurrentPage');
          this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Загружено',
          });
        }
        console.log(response);
      } catch (_) {
        // Do nothing
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>
